import React from "react";
import "../../newStyles.scss";
import { momentEs } from "../../../../services/moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../common/Button";

const Debit = ({ balance, setCargos }) => {
  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setCargos("resumen");
    }
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-full h-full overflow-hidden z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center"
        onClick={(e) => {
          closeModal(e);
        }}
      >
        <div className="p-8 flex flex-col gap-4 relative bg-white max-w-2xl">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(e) => {
              closeModal(e);
            }}
            width={24}
            className="absolute top-4 right-4 cursor-pointer"
          />
          <h4 className="text-xl font-bold">Pago con tarjeta de débito</h4>
          <p>
            Puedes usar esta opción sólo en caso de tener algún <strong>problema</strong> con tu <strong>cuenta</strong> para el <strong>cargo</strong> automático de tu pago.
          </p>
          <p>
            Te sugerimos que al usarla realices el <strong>pago antes</strong> de la fecha de <strong>vencimiento</strong> para evitar aclaraciones.
          </p>
          <div className="flex justify-between gap-8 flex-col lg:flex-row">
            <div className="flex flex-col max-w-3xl">
              <div className="p-4 bg-green-light">
                <p className="font-bold text-xl uppercase text-white">DETALLES DEL PAGO</p>
              </div>
              <div className="flex items-center gap-8 justify-between px-4 py-2">
                <p>Capital:</p>
                <div>
                  <p className="font-bold">
                    {balance.curentInstallment.principalBalance.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                    <small> MXN</small>
                  </p>
                </div>
              </div>
              <div className="w-full h-px bg-gray-200" />
              <div className="flex items-center gap-8 justify-between px-4 py-2">
                <p>Intereses:</p>
                <div>
                  <p className="font-bold">
                    {balance.curentInstallment.interest.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                    <small> MXN</small>
                  </p>
                </div>
              </div>
              <div className="w-full h-px bg-gray-200" />

              <div className="flex items-center gap-8 justify-between px-4 py-2">
                <p>Monto a pagar:</p>
                <div>
                  <p className="font-bold">
                    {balance.curentInstallment.paymentValue.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                    <small> MXN</small>
                    <br />
                    <small> IVA incluído</small>
                  </p>
                </div>
              </div>
              <div className="w-full h-px bg-gray-200" />
              <div className="flex items-center gap-8 justify-between px-4 py-2">
                <p>Vencimiento:</p>
                <div>
                  <p className="font-bold">{momentEs(balance.curentInstallment.dueDate).format("D/MMM/Y")}</p>
                </div>
              </div>
              <div className="w-full h-px bg-gray-200" />
            </div>

            <div className="max-w-md flex flex-col gap-4">
              <p>
                Por favor presiona el botón de abajo y serás redirigido a la plataforma de pago. Ahí deberás llenar toda la información requerida y podrás hacer tu pago usando tu número de préstamo: <b>{balance.reference}</b>
              </p>
              <p>La seguridad de tu información está garantizada</p>
              <div className="flex-distributed" style={{ marginBottom: "1rem" }}>
                <img width="100" src="/img/mastercard.svg" alt="mastercard" />
                <img width="100" src="/img/visa.png" alt="visa" />
              </div>
              <a href="https://4finance.mit.com.mx/vivWeb/" rel="noopener noreferrer" target="_blank">
                <Button className="text-center">PAGAR CON TARJETA DE DÉBITO</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Debit;
