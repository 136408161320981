import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronDown } from "@fortawesome/free-solid-svg-icons";

export const AccordionFaq = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="py-0 cursor-pointer">
      <div onClick={() => setOpen(!open)} className={`flex text-lg py-2 pl-4 ${open ? "bg-slate-100 cursor-pointer" : " "}`}>
        <div className="text-green-light text-lg my-0 mr-4">{open ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}</div>
        <div className="font-bold text-xl">{question}</div>
      </div>
      <div className={`h-auto transition-all text-md bg-slate-100 p-4 text-left ${open ? "" : "hidden"}`}>{answer}</div>
    </div>
  );
};
