import React, { useState } from "react";
import { useEffect } from "react";
import { BallClipRotate } from "react-pure-loaders";
import { getOXXOBEPaymentReference, getOXXOPaymentReference, getToken } from "../../../../../services/api";
import Button from "../../../../common/Button";

const idProduct = 1;

const CashOption = ({ extensionReference }) => {
  const [loading, setLoading] = useState(false);
  const [extension, setExtension] = useState(false);
  const [, setEmail] = useState("");
  const [reference, setReference] = useState(null);
  const [, setSentByMail] = useState(false);

  const requestRef = () => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (demoUser || user.eMail === "demo@demo.com") {
      setExtension(true);
      setEmail(user.eMail);
      return setLoading(false);
    }

    setLoading(true);
    if (user) {
      getData(user);
    } else setLoading(false);
    setLoading(false);
    setEmail(user.eMail);
    return setExtension(true);
  };

  const oxxoData = async (user, validToken) => {
    const data = {
      idProduct,
      idCustomer: user.customerId,
    };
    getOXXOPaymentReference(data, validToken)
      .then((res) => {
        setReference(res.data.paymentReference);
        setSentByMail(true);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const getData = async (user) => {
    // Here goes post to get user debt instead of session storage
    let response = await getToken();
    if (!response) return setLoading(false);
    let validToken = response.data.token;
    oxxoData(user, validToken).then((data) => console.log(data));
  };

  const oxxoBeData = async (user, validToken) => {
    const data = {
      idProduct,
      idCustomer: user.customerId,
    };
    getOXXOBEPaymentReference(data, validToken)
      .then((res) => {
        setReference(res.data.paymentReference);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    const fn = async () => {
      let user = JSON.parse(sessionStorage.getItem("loggedUser"));
      let response = await getToken();
      if (!response) return setLoading(false);
      let validToken = response.data.token;
      oxxoBeData(user, validToken).then((data) => console.log(data));
    };
    fn();
  }, []);

  return (
    <div className="flex flex-col gap-4 py-8">
      <>
        <h4 className="text-xl font-bold text-green-dark">Efectivo</h4>

        <ol>
          <li>Acude a cualquier tienda participante (OXXO) y realiza tu pago en efectivo con el código de referencia.</li>
          <li>Dando click en el botón de abajo te enviaremos tu número de referencia por correo electrónico.</li>
        </ol>
        {reference && (
          <p>
            Número de referencia: <strong className="font-bold">{reference}</strong>
          </p>
        )}
        {extension ? (
          <p className="border-2 border-green-dark flex items-center justify-center p-4">Hemos enviado la referencia a tu correo electrónico</p>
        ) : (
          <Button onClick={requestRef} className="text-center w-56 self-center">
            {loading ? <BallClipRotate loading color="white" /> : "ENVIAR REFERENCIA POR CORREO ELECTRÓNICO"}
          </Button>
        )}
      </>
    </div>
  );
};

export default CashOption;
