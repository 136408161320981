import TagManager from "react-gtm-module";

export const useAnalytics = () => {
  /**
   * Function that register an event adding the userId if obtained.
   * @param {String} event
   * @param {number} userId
   */
  const dataLayerPushEvent = (event, userId) => {
    const tagManagerArgs = {
      dataLayer: {
        event,
      },
    };
    if (userId) {
      tagManagerArgs.dataLayer.user_id = userId;
    }

    TagManager.dataLayer(tagManagerArgs);
  };

  /**
   * It allows us to save the user ID on data layer
   * @param {number} userId
   */
  const dataLayerRegisterUserId = (userId) => {
    const tagManagerArgs = {
      dataLayer: {
        user_id: userId,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  };

  return { dataLayerPushEvent, dataLayerRegisterUserId };
};
