import React from "react";
import Steps from "rc-steps";
import "rc-steps/assets/index.css";

const { Step } = Steps;

export const RegisterSteps = ({ props, currentStep }) => {
  return (
    <Steps
      type="navigation"
      current={currentStep}
      onChange={(current) => {
        if (current === 0) return props.history.push("/registration");
        if (current === 1) return props.history.push("/registration/personal-details");
        if (current === 2) return props.history.push("/registration/employment-details");
        if (current === 3) return props.history.push("/registration/nip-bureau");
        if (current === 4) return props.history.push("/registration/identity");
        if (current === 5) return props.history.push("/registration-complete");
        if (current === 6) return props.history.push("/registration/questionary");
      }}
    >
      <Step className="py-4 pr-4" icon={<span />} wrapperStyle={{ backgroundColor: "red" }} disabled={currentStep > 2} title="Registro" />
      <Step className="py-4 px-4" icon={<span />} wrapperStyle={{ backgroundColor: "red" }} disabled={currentStep < 1 || currentStep > 2} title="Datos personales y Dirección" />
      <Step className="py-4 px-4" icon={<span />} wrapperStyle={{ backgroundColor: "red" }} disabled={currentStep < 2 || currentStep > 2} title="Detalles de Empleo" />
      <Step className="py-4 px-4" icon={<span />} wrapperStyle={{ backgroundColor: "red" }} disabled={true} title="Verificación" />
      <Step className="py-4 pl-4" icon={<span />} wrapperStyle={{ backgroundColor: "red" }} disabled={true} title="Finalizada" />
    </Steps>
  );
};
