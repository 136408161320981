import React from "react";
import Button from "../../../../common/Button";

const DebitOption = ({ reference }) => {
  return (
    <div className="flex flex-col gap-4 py-8">
      <h4 className="text-xl font-bold text-green-dark">Tarjeta de débito</h4>
      <p>
        Por favor presiona el botón de abajo y serás redirigido a la plataforma de pago. Ahí deberás llenar toda la información requerida y podrás hacer tu pago usando tu número de préstamo: <b>{reference}</b>
      </p>
      <p>La seguridad de tu información está garantizada</p>
      <div className="flex-distributed" style={{ marginBottom: "1rem" }}>
        <img width="100" src="/img/mastercard.svg" alt="mastercard" />
        <img width="100" src="/img/visa.png" alt="visa" />
      </div>
      <a href="https://4finance.mit.com.mx/vivWeb/" rel="noopener noreferrer" target="_blank">
        <Button className="text-center">PAGAR CON TARJETA DE DÉBITO</Button>
      </a>
    </div>
  );
};

export default DebitOption;
