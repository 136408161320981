import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import BankOption from "../pay-components/liquidate-components/BankOption";
import DebitOption from "../pay-components/liquidate-components/DebitOption";
import CashOption from "../pay-components/liquidate-components/CashOption";
import { getToken, requestExtension } from "../../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import MoveItem from "./MoveItem";

import { loadExtension } from "../dashboardChecks.js";

const idProduct = 1;
const MoveOption = ({ user, loanNumber }) => {
  const [willPay, setWillPay] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [extensionData, setExtensionData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [extensionError, setExtensionError] = useState(null);
  const [reference, setReference] = useState(null);

  const setExtension = async (days) => {
    setWillPay(false);
    setReference(null);
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    let loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (demoUser || loggedUser.eMail === "demo@demo.com") {
      setReference(123456789);
      return setWillPay(true);
    }
    let response = await getToken();
    if (!response) return setServerError(true);
    let validToken = response.data.token;
    const data = {
      idAction: 2,
      days,
      idCustomer: user.customerId,
      idProduct,
    };

    requestExtension(data, validToken).then((res) => {
      const { data } = res;
      if (data) {
        // ALLOW EXTENSION
        setWillPay(true);
        setReference(data.details[0].payCashReference);
      }
      if (!data) setExtensionError("Server Error: 400");
    });
  };

  useEffect(() => {
    loadExtension(setExtensionData, setServerError, setExtensionError);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-4" id="extensions">
        <h4 className="text-green-dark font-bold text-xl">Recuerda realizar el pago de tu extensión para hacerla válida y recorrer la fecha de pago de tu préstamo.</h4>
        {!extensionError && <p className="text-lg">Tienes disponibles los siguientes esquemas de extensión:</p>}
        {extensionError && <p className="font-bold text-lg">{extensionError}</p>}
        <div className="flex gap-4 flex-col lg:flex-row">
          {extensionData?.map((element, index) => (
            <MoveItem key={index} element={element} selected={selected} extensionError={extensionError} serverError={serverError} setExtension={setExtension} setSelected={setSelected} />
          ))}
        </div>
      </div>

      {willPay && (
        <div
          className="fixed top-0 left-0 w-full h-full overflow-hidden z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setSelected(null);
              setWillPay(false);
            }
          }}
        >
          <div className="p-8 flex flex-col gap-4 relative bg-white max-w-2xl">
            <FontAwesomeIcon
              icon={faTimes}
              onClick={(e) => {
                setSelected(null);
                setWillPay(false);
              }}
              width={24}
              className="absolute top-4 right-4 cursor-pointer"
            />
            <h4 className="text-xl font-bold">¿Cómo pagar el servicio para recorrer fecha de pago?</h4>
            <Tabs>
              <TabList className="flex flex-col lg:flex-row">
                <Tab className="flex-1 p-4 border-b border-gray-100 rounded-none cursor-pointer">Efectivo en tiendas</Tab>
                <Tab className="flex-1 p-4 border-b border-gray-100 rounded-none cursor-pointer">Transferencia</Tab>
                <Tab className="flex-1 p-4 border-b border-gray-100 rounded-none cursor-pointer">Tarjeta de Débito</Tab>
              </TabList>
              <TabPanel>
                <CashOption extensionReference={reference} moveImgWidth={"200px"} />
              </TabPanel>
              <TabPanel>
                <BankOption />
              </TabPanel>
              <TabPanel>
                <DebitOption moveLoan={true} reference={loanNumber} />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
};

export default MoveOption;
