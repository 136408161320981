import React from "react";
import LinkButton from "../../common/LinkButton";

const Options = ({ user, setSection, maskedBank }) => {
  return (
    <div className="flex flex-col w-full max-w-2xl">
      <h3 className="text-2xl">Revisión de la cuenta</h3>
      <div className="flex items-center justify-between py-2">
        <div className="flex flex-col">
          <p>Tu nombre</p>
          <p className="font-bold">{user ? user.fullName : "Cargando..."}</p>
        </div>
      </div>
      <div className="w-full h-px bg-gray-200" />

      <div className="flex items-center justify-between py-2">
        <div className="flex flex-col">
          <p>Correo Electrónico</p>
          <p className="font-bold">{user ? user.eMail : "Cargando..."}</p>
        </div>
        {/* <LinkButton onClick={() => setSection("email")}>EDITAR</LinkButton> */}
      </div>
      <div className="w-full h-px bg-gray-200" />
      <div className="flex items-center justify-between py-2">
        <div className="flex flex-col">
          <p>Número de teléfono</p>
          <p className="font-bold">{user ? user.mobile : "Cargando..."}</p>
        </div>
        <LinkButton onClick={() => setSection("phone")}>EDITAR</LinkButton>
      </div>
      <div className="w-full h-px bg-gray-200" />
      <div className="flex items-center justify-between py-2">
        <div className="flex flex-col">
          <p>Contraseña</p>
          <p className="font-bold">******</p>
        </div>
        <LinkButton onClick={() => setSection("password")}>EDITAR</LinkButton>
      </div>
      <div className="w-full h-px bg-gray-200" />
      <div className="flex items-center justify-between py-2">
        <div className="flex flex-col">
          <p>
            Mis cuentas <br />
            (aquí depositamos tu préstamo)
          </p>
          <p className="font-bold">{maskedBank}</p>
        </div>
        <LinkButton onClick={() => setSection("payment")}>EDITAR</LinkButton>
      </div>
      <div className="w-full h-px bg-gray-200" />
    </div>
  );
};

export default Options;
