import React from "react";
import { BallClipRotate } from "react-pure-loaders";
import { momentEs } from "../../../../services/moment";
import Button from "../../../common/Button";

const MoveItem = ({ element, selected, extensionError, serverError, setExtension, setSelected }) => {
  const { days, newDueDate, extensionFee } = element;

  return (
    <div className={`flex-1 border-2 border-green-dark p-8 flex flex-col gap-4 ${selected === days ? "bg-green-dark text-white" : ""}`}>
      <div className="flex flex-col gap-1 justify-center items-center">
        <p className="text-lg">Costo de la extensión</p>
        <p className="text-2xl font-bold">
          {extensionFee.toLocaleString("en-US", { style: "currency", currency: "USD" })}
          <span className="text-xs"> MXN</span>
        </p>
      </div>
      <div className="flex flex-col gap-1 justify-center items-center">
        <p className="text-lg">Plazo</p>
        <p className="text-2xl font-bold">
          {days} <span className="font-light text-xs">días</span>
        </p>
      </div>
      <div className="flex flex-col gap-1 justify-center items-center">
        <p className="text-lg">Nueva fecha de pago</p>
        <p className="text-2xl font-bold">{momentEs(newDueDate).format("D/MMM/Y")}</p>
      </div>

      {serverError || extensionError ? (
        <span className="text-gray-500 self-center"> Error al solicitar extensión</span>
      ) : (
        <Button
          className={`w-56 self-center ${selected === days ? "pointer-events-none" : ""}`}
          onClick={
            extensionError
              ? null
              : () => {
                  setExtension(element.days);
                  setSelected(element.days);
                }
          }
        >
          {selected === days ? <BallClipRotate color={"white"} loading /> : "Solicitar extensión"}
        </Button>
      )}
    </div>
  );
};

export default MoveItem;
