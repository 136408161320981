import React from "react";
import "./creditReport.scss";
import growMoneySvg from "../../assets/svg/grow-money.svg";
import creditCardSvg from "../../assets/svg/credit-card.svg";
import vivusLogo from "../../assets/svg/vivus-logo.svg";

const CreditReportForm = ({ className = "" }) => (
  <div className={`credit-report-form ${className}`}>
    <form>
      <img src={vivusLogo} alt="Vivus" className="hidden-mobile" width={130} />
      <img src={creditCardSvg} alt="Form icon" width={70} />
      <div className="input-group">
        <label htmlFor="firstName">Nombre:</label>
        <input type="text" name="firstName" placeholder="Nombre" />
      </div>
      <div className="input-group">
        <label htmlFor="lastName">Apellidos:</label>
        <input type="text" name="lastName" placeholder="Apellido" />
      </div>
      <div className="input-group">
        <label htmlFor="email">Email:</label>
        <input type="text" name="email" placeholder="nombre@mail.com" />
      </div>
      <div className="icon-button-container">
        <img src={creditCardSvg} width={40} alt="Tarjeta de crédito" />
        <button className="credit-report-form-button">
          Pago con tarjeta de crédito
        </button>
      </div>
      <div className="icon-button-container">
        <img src={creditCardSvg} width={40} alt="Efectivo" />
        <button className="credit-report-form-button">Pago con efectivo</button>
      </div>
    </form>
  </div>
);

const CreditReportPage = () => (
  <>
    <section>
      <div className="credit-report-main-container credit-report-container">
        <div className="credit-report-form-image">
          <img src={growMoneySvg} alt="Reporte de historial crediticio" />
        </div>
        <CreditReportForm />
      </div>
    </section>
  </>
);

export default CreditReportPage;
