import React from "react";
import "./creditReport.scss";
import mainSvg from "../../assets/svg/credit-report-main.svg";
import creditReportSvg from "../../assets/svg/credit-report-icon.svg";
import whyReportSvg from "../../assets/svg/credit-chart-icon.svg";
import moneyReportSvg from "../../assets/svg/report-money-icon.svg";

const CreditReportLegend = ({ className }) => (
  <div className={`credit-report-legend ${className}`}>
    <img src={creditReportSvg} alt="Reporte de historial crediticio" />
    <h1 className="text-green">Tu reporte de crédito completo</h1>
    <p className="text-gray">
      Te lo enviamos directo a tu e-mail. Un reporte fácil de entender con toda
      la información para que sepas cuál es tu situación.
    </p>
    <button className="main-button">Obtener mi historial crediticio</button>
    <p className="text-gray">¡Cancela en cualquier momento!</p>
  </div>
);

const WhyReportSection = () => (
  <div className="why-report-section">
    <img src={whyReportSvg} alt="¿Por qué conocer mi historial crediticio?" />
    <div className="indicator" />
    <h1 className="">
      ¿Por qué es importante conocer tu historial crediticio?
    </h1>
    <p className="text-gray">
      La información sobre cómo has manejado tus deudas en el pasado se traduce
      en una puntuación que te permitirá acceder a futuros créditos. A un mayor
      puntaje más probabilidades tendrás de poder aplicar a diferentes créditos.
    </p>
    <p className="text-gray">
      Con un puntaje bajo, es posible que aún puedas obtener crédito, pero no te
      arriesgues a pasar un mal rato o llevarte decepciones en las entidades
      financieras.
    </p>
  </div>
);

const HowToBuildSection = () => (
  <div className="why-report-section">
    <img src={moneyReportSvg} alt="¿Por qué conocer mi historial crediticio?" />
    <div className="indicator font-title" />
    <h1>¿Cómo puedo construir mi puntaje crediticio?</h1>
    <p className="text-gray">
      Los dos factores más importantes en tu puntaje de crédito son el historial
      de pagos y la utilización del crédito. Por eso toma nota de estos
      consejos:
    </p>
    <div className="indicator">1</div>
    <span className="text-gray">
      Paga todas tus facturas, no solo las tarjetas de crédito, a tiempo. Los
      pagos atrasados y las cuentas canceladas o enviadas a cobranza afectarán
      tu puntaje.
    </span>
    <div className="indicator">2</div>
    <span className="text-gray">
      Mantén tus cuentas abiertas y activas cuando sea posible; eso te brinda un
      historial de pagos más extenso y puede ayudarte a utilizar su crédito.
    </span>
    <div className="indicator">3</div>
    <span className="text-gray">
      Evita abrir demasiadas cuentas nuevas a la vez. Las cuentas nuevas reducen
      la antigüedad promedio de tu cuenta y cada aplicación provoca un pequeño
      impacto en tu puntaje.
    </span>
  </div>
);

const CreditReportPage = () => (
  <>
    <section>
      <div className="credit-report-main-container credit-report-container">
        <div className="credit-report-image">
          <img src={mainSvg} alt="Reporte de historial crediticio" />
        </div>
        <CreditReportLegend className="only-desktop" />
      </div>
      <div className="credit-report-divider" />
    </section>
    <section>
      <div className="credit-report-container credit-report-info-container">
        <CreditReportLegend className="only-mobile" />
        <WhyReportSection />
        <HowToBuildSection />
        <button className="main-button">Obtener mi historial crediticio</button>
      </div>
    </section>
  </>
);

export default CreditReportPage;
