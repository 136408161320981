import React from "react";

const Options = ({ setCargos }) => {
  return (
    <div className="flex flex-col gap-2">
      <h4 className="text-xl text-green-dark font-bold">Métodos de pago</h4>
      <p className="text-lg">Ahora selecciona el método de pago que deseas utilizar:</p>
      <div className="flex flex-col lg:flex-row gap-6">
        <button onClick={() => setCargos("cash")} className="border-2 border-green-dark p-4 flex-1 hover:bg-gray-50 font-bold uppercase text-green-dark">
          Efectivo
        </button>
        <button onClick={() => setCargos("debit")} className="border-2 border-green-dark p-4 flex-1 hover:bg-gray-50 font-bold uppercase text-green-dark">
          Tarjeta débito
        </button>

        <button onClick={() => setCargos("bank")} className="border-2 border-green-dark p-4 flex-1 hover:bg-gray-50 font-bold uppercase text-green-dark">
          Transferencia
        </button>
      </div>
    </div>
  );
};

export default Options;
