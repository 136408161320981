import publicIp from "public-ip";
import { useEffect, useState } from "react";

export const useLocation = () => {
  const [location, setLocation] = useState("");
  const [ip, setIp] = useState("");

  const requestLocation = () => {
    if (sessionStorage.coords) {
      setLocation(sessionStorage.coords);
    } else {
      navigator.geolocation.getCurrentPosition(
        ({ coords, timestamp }) => {
          sessionStorage.setItem("coords", coords.latitude + ", " + coords.longitude + ", " + timestamp);
          setLocation(sessionStorage.coords);
        },
        (err) => {
          console.log(err);

          sessionStorage.setItem("coords", err.message);
          setLocation(sessionStorage.coords);
        }
      );
    }
  };

  const requestIp = async () => {
    if (sessionStorage.ip) {
      setIp(sessionStorage.ip);
    } else {
      const myIp = await publicIp.v4();
      if (myIp) {
        sessionStorage.setItem("ip", myIp);
      } else {
        sessionStorage.setItem("Unable to get IP address");
      }
      setIp(sessionStorage.ip);
    }
  };

  useEffect(() => {
    requestLocation();
    requestIp();
  }, []);

  return { location, ip };
};
