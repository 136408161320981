import React, { useEffect, useState } from "react";
import { getToken, getOXXOPaymentReference, getOXXOBEPaymentReference } from "../../../../services/api";
import { BallClipRotate } from "react-pure-loaders";
import { momentEs } from "../../../../services/moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../common/Button";

const idProduct = 1;

const Cash = ({ balance, setCargos }) => {
  const [loading, setLoading] = useState(false);
  const [reference, setReference] = useState(null);
  const [sentByMail, setSentByMail] = useState(false);

  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setCargos("resumen");
    }
  };

  const oxxoData = async (user, validToken) => {
    const data = {
      idProduct,
      idCustomer: user.customerId,
    };
    getOXXOPaymentReference(data, validToken)
      .then((res) => {
        setReference(res.data.paymentReference);
        setSentByMail(true);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const oxxoBeData = async (user, validToken) => {
    const data = {
      idProduct,
      idCustomer: user.customerId,
    };
    getOXXOBEPaymentReference(data, validToken)
      .then((res) => {
        setReference(res.data.paymentReference);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const getData = async (user) => {
    // Here goes post to get user debt instead of session storage
    let response = await getToken();
    if (!response) return setLoading(false);
    let validToken = response.data.token;
    oxxoData(user, validToken).then((data) => console.log(data));
  };

  useEffect(() => {
    const fn = async () => {
      let user = JSON.parse(sessionStorage.getItem("loggedUser"));
      let response = await getToken();
      if (!response) return setLoading(false);
      let validToken = response.data.token;
      oxxoBeData(user, validToken).then((data) => console.log(data));
    };
    fn();
  }, []);

  const requestRef = () => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (demoUser || user.eMail === "demo@demo.com") {
      return setReference(1234789456123);
    }
    setLoading(true);
    if (user) {
      getData(user);
    } else setLoading(false);
  };
  return (
    <div
      className="fixed top-0 left-0 w-full h-full overflow-hidden z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center"
      onClick={(e) => {
        closeModal(e);
      }}
    >
      <div className="p-8 flex flex-col gap-4 relative bg-white max-w-2xl">
        <FontAwesomeIcon
          icon={faTimes}
          onClick={(e) => {
            closeModal(e);
          }}
          width={24}
          className="absolute top-4 right-4 cursor-pointer"
        />
        <h4 className="text-xl font-bold">Pago en efectivo</h4>
        <p>
          Puedes usar esta opción sólo en caso de tener algún <strong>problema</strong> con tu <strong>cuenta</strong> para el <strong>cargo</strong> automático de tu pago.
        </p>
        <p>
          Te sugerimos que al usarla realices el <strong>pago antes</strong> de la fecha de <strong>vencimiento</strong> para evitar aclaraciones.
        </p>
        <div className="flex justify-between gap-8 flex-col lg:flex-row">
          <div className="flex flex-col max-w-3xl">
            <div className="p-4 bg-green-light">
              <p className="font-bold text-xl uppercase text-white">DETALLES DEL PAGO</p>
            </div>
            <div className="flex items-center gap-8 justify-between px-4 py-2">
              <p>Capital:</p>
              <div>
                <p className="font-bold">
                  {balance.curentInstallment.principalBalance.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                  <small> MXN</small>
                </p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center gap-8 justify-between px-4 py-2">
              <p>Intereses:</p>
              <div>
                <p className="font-bold">
                  {balance.curentInstallment.interest.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                  <small> MXN</small>
                </p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />

            <div className="flex items-center gap-8 justify-between px-4 py-2">
              <p>Monto a pagar:</p>
              <div>
                <p className="font-bold">
                  {balance.curentInstallment.paymentValue.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                  <small> MXN</small>
                  <br />
                  <small> IVA incluído</small>
                </p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
            <div className="flex items-center gap-8 justify-between px-4 py-2">
              <p>Vencimiento:</p>
              <div>
                <p className="font-bold">{momentEs(balance.curentInstallment.dueDate).format("D/MMM/Y")}</p>
              </div>
            </div>
            <div className="w-full h-px bg-gray-200" />
          </div>

          <div className="max-w-md flex flex-col gap-4">
            <ol>
              <li>Acude a cualquier tienda participante (OXXO) y realiza tu pago en efectivo con el código de referencia.</li>
              <li>Dando click en el botón de abajo te enviaremos tu número de referencia por correo electrónico.</li>
            </ol>
            {reference && (
              <p>
                Número de referencia: <strong className="font-bold">{reference}</strong>
              </p>
            )}
            {sentByMail ? (
              <p className="border-2 border-green-dark flex items-center justify-center p-4">Hemos enviado la referencia a tu correo electrónico</p>
            ) : (
              <Button onClick={requestRef} className="text-center">
                {loading ? <BallClipRotate loading color="white" /> : "ENVIAR REFERENCIA POR CORREO ELECTRÓNICO"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cash;
