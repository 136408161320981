import React, { useState } from "react";
import Options from "./pay-components/Options";
import "../newStyles.scss";
import Liquidate from "./pay-components/Liquidate";
import Bank from "./pay-components/Bank";
import Debit from "./pay-components/Debit";
import Cash from "./pay-components/Cash";

const Pay = ({ balance }) => {
  const [cargos, setCargos] = useState("resumen");

  return (
    <div className="pagar-container" id="payment">
      <Options cargos={cargos} setCargos={setCargos} />
      {cargos === "liquidate" ? <Liquidate balance={balance} /> : cargos === "bank" ? <Bank balance={balance} setCargos={setCargos} /> : cargos === "debit" ? <Debit balance={balance} setCargos={setCargos} /> : cargos === "cash" ? <Cash balance={balance} setCargos={setCargos} /> : <div></div>}
    </div>
  );
};

export default Pay;
