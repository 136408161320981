import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getFile, getToken, uploadFile } from "../../services/api";
import Compressor from "compressorjs";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import Button from "./Button";

const idProduct = 1;

const DocumentItem = ({ document: d, validate = () => null }) => {
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState(false);
  const [completed, setCompleted] = useState(false);

  //Errors
  const [fileSizeError, setFileSizeError] = useState(false);
  const [serverError, setServerError] = useState(false);

  const { trackPageView } = useMatomo();

  const handleFile = async (e) => {
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;

    let user = JSON.parse(sessionStorage.getItem("loggedUser"));

    setLoading(true);
    let file = e.target.files[0];
    if (file.size > 10000000) return setFileSizeError(true);
    if (!isDocument(d.idDocument)) {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1024,
        success(result) {
          // result.name.substring(result.name.lastIndexOf('.') + 1);
          let extension = result.type.replace("image/", "");
          let reader = new FileReader();
          reader.onprogress = (e) => {
            setLoading(true);
          };
          reader.onload = (e) => {
            setDoc(e.target.result);
            const finalString = e.target.result.replace(`data:image/${extension};base64,`, "");
            const data = {
              idProduct,
              idCustomer: user.customerId,
              idDocument: d.idDocument,
              fileName: result.name,
              fileExtension: extension,
              fileContent: finalString,
            };
            uploadFile(data, validToken)
              .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                  trackPageView({
                    documentTitle: "Pictures Loading",
                    href: "https://dfi.world",
                  });
                  validate(d.idDocument);
                  return setCompleted(true);
                }
                return setServerError(true);
              })
              .catch((err) => setLoading(false));
          };
          reader.readAsDataURL(result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    } else {
      let extension = file.type.replace("application/", "");
      let reader = new FileReader();
      reader.onprogress = (e) => {
        setLoading(true);
      };
      reader.onload = (e) => {
        console.log(e);
        setDoc(e.target.result);
        const finalString = e.target.result.replace(`data:application/${extension};base64,`, "");
        const data = {
          idProduct,
          idCustomer: user.customerId,
          idDocument: d.idDocument,
          fileName: file.name,
          fileExtension: extension,
          fileContent: finalString,
        };
        uploadFile(data, validToken)
          .then((res) => {
            setLoading(false);
            if (res.status === 200) {
              trackPageView({
                documentTitle: "Pictures Loading",
                href: "https://dfi.world",
              });
              validate(d.idDocument);
              return setCompleted(true);
            }
            return setServerError(true);
          })
          .catch((err) => setLoading(false));
      };
      reader.readAsDataURL(file);
    }
  };

  const showDefaultImage = (idDocument) => {
    switch (idDocument) {
      case 27:
        return "/img/anverso.png";
      case 30:
        return "/img/reverso.png";
      case 39:
        return "/img/selfie.png";
      default:
        return "/img/document.png";
    }
  };

  const isDocument = (idDocument) => idDocument !== 27 && idDocument !== 30 && idDocument !== 39;

  useEffect(() => {
    const a = async () => {
      let response = await getToken();
      if (!response) return;
      let validToken = response.data.token;

      let { customerId } = JSON.parse(sessionStorage.getItem("loggedUser"));

      const data = {
        idProduct,
        idCustomer: customerId,
        idDocument: d.idDocument,
      };

      getFile(data, validToken)
        .then((res) => {
          if (res.status === 200) {
            setCompleted(true);
            validate(d.idDocument);
            setDoc(`data:image/${res.data.fileExtension.split(".")[1]};base64,${res.data.fileContent}`);
          }
        })
        .catch((err) => console.log(err));
    };
    a();
  }, []);

  return (
    <div className="flex flex-col gap-4 max-w-lg lg:w-1/2 border border-gray-100 p-4">
      <div className="flex flex-col gap-4">
        <p className="font-bold">{d.documentDescription}</p>
        <div className="flex flex-col-reverse lg:flex-row justify-between items-center gap-4">
          <input onChange={handleFile} className="hidden" type="file" accept={isDocument(d.idDocument) ? "application/pdf" : "image/*"} name={`document-${d.idDocument}`} id={`document-${d.idDocument}`} capture={d.idDocument === 39 ? "user" : "environment"} />

          <img className="object-contain h-96 w-auto" src={isDocument(d.idDocument) ? showDefaultImage(d.idDocument) : doc || showDefaultImage(d.idDocument)} alt={d.documentDescription} />
        </div>
        <Button onClick={() => document.getElementById("document-" + d.idDocument).click()}>{loading ? "CARGANDO..." : completed ? (isDocument(d.idDocument) ? "CAMBIAR ARCHIVO" : "CAMBIAR IMAGEN") : "CARGAR"}</Button>
        {fileSizeError ? <small className="text-red-500">El archivo no debe pesar más de 10MB</small> : null}
        {serverError ? <small className="text-red-500">Error en el servidor, intenta nuevamente</small> : null}
      </div>
      <div className={` bg-cover bg-left bg-no-repeat lg:bg-center p-4 text-center flex items-center justify-center text-gray-800 ${doc ? "bg-gray-100 border-2 border-green-dark" : "bg-gray-100"}`}>
        {!doc && !isDocument(d.idDocument) && <span>Toma una foto desde tu celular o selecciónala desde tu computadora.</span>}
        {!doc && isDocument(d.idDocument) && <span>Sube el documento en formato PDF.</span>}
        {doc && (
          <>
            <img src={"/img/check.png"} width={24} />
            <span className="ml-4 font-bold">{isDocument(d.idDocument) ? "Archivo cargado exitosamente" : "Imagen cargada exitosamente"}</span>
          </>
        )}
      </div>
    </div>
  );
};

DocumentItem.propTypes = {};
DocumentItem.defaultValues = {};

export default DocumentItem;
