import React from "react";

const ChangePayment = ({ setSection }) => {
  return (
    <div className="flex flex-col gap-4  lg:max-w-2xl">
      <h3 className="text-2xl">Cambia aquí tu CLABE o Tarjeta de Débito</h3>

      <div onClick={() => setSection("payment-bank")} className="flex gap-4 items-center justify-between py-2 cursor-pointer">
        <div className="flex gap-4">
          <img src="/icons/uEA27-disbursement_bank.svg" width="32" alt="bank" />
          <p>Cuenta bancaria</p>
        </div>
        <img src="/icons/uE020-chevron_right.svg" width="12" alt="chev right" />
      </div>
      <div className="w-full h-px bg-gray-200" />
      <div onClick={() => setSection("payment-debit")} className="flex gap-4 items-center justify-between py-2 cursor-pointer">
        <div className="flex gap-4">
          <img src="/icons/uEA28-disbursement_card.svg" width="32" alt="debit" />
          <p>Tarjeta de débito</p>
        </div>
        <img src="/icons/uE020-chevron_right.svg" width="12" alt="chev right" />
      </div>
      <div className="w-full h-px bg-gray-200" />
      <div onClick={() => setSection("home")} className="underline text-green-dark cursor-pointer">
        <p>Cancelar</p>
      </div>
    </div>
  );
};

export default ChangePayment;
