import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faServer, faClock } from "@fortawesome/free-solid-svg-icons";

export const ErrorTimeout = ({ location }) => {
  return (
    <div className="app-container">
      <div className="error-page">
        <h1>Verificando información</h1>
        <div className="background-error-icon">
          <FontAwesomeIcon icon={faServer} />
          <span style={{ fontSize: "3rem", marginLeft: "1rem" }}>
            <FontAwesomeIcon icon={faClock} />
          </span>
        </div>
        <h2>Este proceso está tomando más tiempo del previsto.</h2>
        <h3>
          Espera unos segundos y presiona el botón de <b>Verificar estado</b> para continuar con tu aplicación.
        </h3>
        <p>{location.state ? `${location.state.endpoint} code ${location.state.status}` : null}</p>
        <div className="return-error-button">
          <Link to="/pre-approved">Verificar estado</Link>
        </div>
      </div>
    </div>
  );
};
