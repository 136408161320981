import React, { useState, useEffect } from "react";
import { getToken, getBankPaymentReference } from "../../../../services/api";
import { momentEs } from "../../../../services/moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const idProduct = 1;

const Bank = ({ balance, setCargos }) => {
  const [setServerError] = useState(false);
  const [bank0, setBank0] = useState({});

  const bankData = async (user, idBank, validToken) => {
    const data = {
      idProduct,
      idCustomer: user.customerId,
      idBank,
    };
    if (idBank === 3) {
      getBankPaymentReference(data, validToken)
        .then((res) => setBank0(res.data))
        .catch((err) => setServerError(true));
    }
  };

  const getData = async (user) => {
    // Here goes post to get user debt instead of session storage
    let response = await getToken();
    if (!response) return setServerError(true);
    let validToken = response.data.token;
    bankData(user, 3, validToken); //BBVA
  };

  const closeModal = (e) => {
    if (e.target === e.currentTarget) {
      setCargos("resumen");
    }
  };

  useEffect(() => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (demoUser || user.eMail === "demo@demo.com") {
      setBank0({
        account: "25478154",
        paymentReference: "12354874561237862",
      });
    }
    if (user) {
      getData(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="fixed top-0 left-0 w-full h-full overflow-hidden z-50 bg-gray-800 bg-opacity-75 flex items-center justify-center"
        onClick={(e) => {
          closeModal(e);
        }}
      >
        <div className="p-8 flex flex-col gap-4 relative bg-white max-w-2xl">
          <FontAwesomeIcon
            icon={faTimes}
            onClick={(e) => {
              closeModal(e);
            }}
            width={24}
            className="absolute top-4 right-4 cursor-pointer"
          />
          <h4 className="text-xl font-bold">Transferencia</h4>
          <p>
            Puedes transferir de cualquier cuenta de banco sigue las instrucciones de abajo: Te sugerimos realizar el <strong className="font-bold">pago antes</strong> de la <strong className="font-bold">fecha de vencimiento</strong> para evitar aclaraciones.
          </p>
          <div className="flex justify-between gap-8 flex-col lg:flex-row">
            <div className="flex flex-col max-w-3xl">
              <div className="p-4 bg-green-light">
                <p className="font-bold text-xl uppercase text-white">DETALLES DEL PAGO</p>
              </div>
              <div className="flex items-center gap-8 justify-between px-4 py-2">
                <p>Capital:</p>
                <div>
                  <p className="font-bold">
                    {balance.curentInstallment.principalBalance.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                    <small> MXN</small>
                  </p>
                </div>
              </div>
              <div className="w-full h-px bg-gray-200" />
              <div className="flex items-center gap-8 justify-between px-4 py-2">
                <p>Intereses:</p>
                <div>
                  <p className="font-bold">
                    {balance.curentInstallment.interest.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                    <small> MXN</small>
                  </p>
                </div>
              </div>
              <div className="w-full h-px bg-gray-200" />

              <div className="flex items-center gap-8 justify-between px-4 py-2">
                <p>Monto a pagar:</p>
                <div>
                  <p className="font-bold">
                    {balance.curentInstallment.paymentValue.toLocaleString("en-US", { style: "currency", currency: "USD" })}
                    <small> MXN</small>
                    <br />
                    <small> IVA incluído</small>
                  </p>
                </div>
              </div>
              <div className="w-full h-px bg-gray-200" />
              <div className="flex items-center gap-8 justify-between px-4 py-2">
                <p>Vencimiento:</p>
                <div>
                  <p className="font-bold">{momentEs(balance.curentInstallment.dueDate).format("D/MMM/Y")}</p>
                </div>
              </div>
              <div className="w-full h-px bg-gray-200" />
            </div>

            <div className="max-w-md flex flex-col gap-4">
              <h4 className="text-xl font-bold text-green-dark">Pago por SPEI</h4>
              <ol className="text-left list-decimal">
                <li>Ingresar al portal de banca electrónica de tu banco.</li>
                <li>Selecciona la opción "Transferencias" o "Pagos".</li>
                <li>
                  Ingresa tu CLABE personalizada: <strong>{bank0.paymentReference}</strong>.
                </li>
                <li>Ingresa el monto total a pagar de tu préstamo.</li>
                <li>Confirma el pago y ¡Listo!</li>
              </ol>
              <img src={"/img/spei.png"} alt="SPEI imagen" style={{ width: "70%", margin: "auto 0" }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Bank;
