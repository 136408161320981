import React, { useState, useEffect } from "react";
import MoveOption from "./move-components/MoveOption";

const Move = ({ loanNumber }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    let demoUser = JSON.parse(sessionStorage.getItem("demoUser"));
    if (demoUser) return setUser(demoUser);
    let user = JSON.parse(sessionStorage.getItem("loggedUser"));
    if (user) setUser(user);
  }, []);

  return <MoveOption user={user} loanNumber={loanNumber} />;
};

export default Move;
