import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import cookie from "react-cookies";
import { getToken, recoverPassword, getCustomerByMail, sendCodeBySMS, sendCodeByWA } from "../../services/api";
import { BallBeat } from "react-pure-loaders";
import Button from "../common/Button";

const idProduct = 1;

const NewForgotPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingCel, setLoadingCel] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [cellError, setCellError] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [userMail, setUserMail] = useState("");

  const goSMS = async () => {
    setLoadingCel(true);
    setNotFound(false);
    setCellError(false);
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    let email = sessionStorage.getItem("recoveryEmail");
    setUserMail(email);
    getCustomerByMail(idProduct, email, validToken).then((res) => {
      if (res.data) {
        sessionStorage.setItem("mobile", res.data.mobile);
        sessionStorage.setItem("idCustomer", res.data.customerId);
        let data = {
          idProduct,
          idCustomer: res.data.customerId,
          isNIP: false,
        };
        //sendCodeByWA(data, validToken);
        return props.history.push("/confirmacion-sms");
        //return sendCodeBySMS(data, validToken).then((res) => props.history.push("/confirmacion-sms"));
      }
      if (res.response.status === 404) {
        setNotFound(true);
        setLoadingCel(false);
        return;
      }
      setCellError(true);
      setLoadingCel(false);
    });
  };

  const goEmail = async () => {
    setLoading(true);
    setNotFound(false);
    setEmailError(false);
    let response = await getToken();
    if (!response) return;
    let validToken = response.data.token;
    let email = sessionStorage.getItem("recoveryEmail");
    setUserMail(email);
    const data = {
      eMail: email,
      idProduct,
    };
    recoverPassword(data, validToken).then((res) => {
      let { data } = res;
      if (data && data.codigo === "200") {
        setEmailError(false);
        return props.history.push("/confirmacion-email");
      }
      if (res.response.status === 404) {
        setNotFound(true);
        setLoading(false);
        return;
      }
      setEmailError(true);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem("loggedUser") && cookie.load("token")) props.history.push("/dashboard/initial");
  }, []);

  return (
    <div className="bg-white relative w-full flex justify-center items-center flex-1">
      <div className="container flex flex-col gap-4 p-4 ">
        <div className="flex flex-col gap-4 py-4 max-w-xl">
          {/* <DemoButton callback={fillDemo} /> */}
          <div className="w-full flex flex-col gap-2">
            <h1 className="text-3xl lg:text-6xl font-bold text-green-dark">Recupera tu acceso</h1>
            <h2 className="text-xl lg:text-2xl text-green-light">Selecciona el mecanismo mediante el cual deseas recuperar tu cuenta.</h2>
          </div>

          <div className="flex flex-col lg:flex-row gap-4 justify-between">
            <div className="border-2 border-green-light p-8 flex flex-col gap-4 items-center justify-center">
              <svg style={{ fill: "#A3CD3A", maxHeight: "73px", maxWidth: "100%" }} viewBox="0 0 79 73" xmlns="http://www.w3.org/2000/svg">
                <g fill-rule="evenodd">
                  <path d="M6 2a4 4 0 0 0-4 4v61a4 4 0 0 0 4 4h67a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H6zm0-2h67a6 6 0 0 1 6 6v61a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6a6 6 0 0 1 6-6z" fill-rule="nonzero"></path>
                  <path d="M20 31a1 1 0 0 0-1 1v25a1 1 0 0 0 1 1h39a1 1 0 0 0 1-1V32a1 1 0 0 0-1-1H20zm0-2h39a3 3 0 0 1 3 3v25a3 3 0 0 1-3 3H20a3 3 0 0 1-3-3V32a3 3 0 0 1 3-3z" fill-rule="nonzero"></path>
                  <path d="M18.464 34.741l-.928 1.772 21.036 11.019a2 2 0 0 0 1.856 0l21.036-11.02-.928-1.77L39.5 45.76 18.464 34.741z" fill-rule="nonzero"></path>
                  <path d="M1 17h77v-2H1zM19 8.5c0 1.382-1.118 2.5-2.5 2.5A2.499 2.499 0 0 1 14 8.5C14 7.118 15.118 6 16.5 6S19 7.118 19 8.5M12 8.5c0 1.382-1.118 2.5-2.5 2.5A2.499 2.499 0 0 1 7 8.5C7 7.118 8.118 6 9.5 6S12 7.118 12 8.5"></path>
                </g>
              </svg>
              <p className="font-bold text-center">POR MAIL</p>
              <p className="text-center">Vamos a enviarte un mail con el código de validación</p>
              <Button onClick={goEmail}>{loading ? <BallBeat color={"#fff"} loading /> : "MAIL"}</Button>
            </div>

            <div className="border-2 border-green-light p-8 flex flex-col gap-4 items-center justify-center">
              <svg style={{ fill: "#A3CD3A", maxHeight: "73px", maxWidth: "100%" }} viewBox="0 0 46 73" xmlns="http://www.w3.org/2000/svg">
                <g fill-rule="evenodd">
                  <path d="M4 2a2 2 0 0 0-2 2v65a2 2 0 0 0 2 2h38a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H4zm0-2h38a4 4 0 0 1 4 4v65a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" fill-rule="nonzero"></path>
                  <path
                    d="M20.65 41.877a2 2 0 0 1 1.077-.092C22.47 41.928 23.23 42 24 42c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12c0 2.504.766 4.888 2.174 6.89a2 2 0 0 1 .302 1.645l-1.593 6.25 7.767-2.908zM10 48l2.538-9.96A13.936 13.936 0 0 1 10 30c0-7.732 6.268-14 14-14s14 6.268 14 14-6.268 14-14 14c-.906 0-1.791-.086-2.65-.25L10 48z"
                    fill-rule="nonzero"
                  ></path>
                  <path d="M27 61.5c0 1.935-1.565 3.5-3.5 3.5a3.498 3.498 0 0 1-3.5-3.5c0-1.935 1.565-3.5 3.5-3.5s3.5 1.565 3.5 3.5"></path>
                </g>
              </svg>
              <p className="font-bold text-center">POR SMS A TU CELULAR</p>
              <p className="text-center">Vamos a enviarte un SMS con el código de validación</p>
              <Button onClick={goSMS}>{loadingCel ? <BallBeat color={"#fff"} loading /> : "SMS"}</Button>
            </div>
          </div>
          {cellError || emailError ? <p className="text-red-500">Ocurrió un problema, intenta nuevamente</p> : null}
          {notFound ? <p className="text-red-500">No hay ningún usuario asociado a {userMail}</p> : null}
          <Link to="/recupera" className="text-green-light underline hover:text-green-dark transition-all">
            Regresar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(NewForgotPassword);
