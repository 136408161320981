import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import cookie from "react-cookies";
import { checkCustomerToken, getToken } from "../../../services/api";
import publicIp from "public-ip";
import { useLogin } from "../../../hooks/login";

const SSO = (props) => {
  const location = useLocation();
  const [token, setToken] = useState();
  const [clientId, setClientId] = useState();
  const { checkStatus } = useLogin();

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    const client = new URLSearchParams(location.search).get("client");
    if (!token || !client) return props.history.push("/");
    setToken(token);
    setClientId(client);
  }, [location]);

  useEffect(() => {
    const a = async () => {
      if (token && clientId) {
        let validToken = cookie.load("token");
        if (!validToken) {
          let response = await getToken();
          if (!response.data) return props.history.push("/error");
          if (response.data) validToken = response.data.token;
        }
        try {
          const myIp = await publicIp.v4();
          const { data } = await checkCustomerToken(
            {
              idProduct: 1,
              idCustomer: clientId,
              customerToken: token,
              customerUserAgent: navigator.userAgent,
              customerIP: myIp || "127.0.0.1",
            },
            validToken
          );

          if (myIp) {
            sessionStorage.setItem("ip", myIp);
            let userAgent = window.navigator.userAgent;
            let coordinates;
            navigator.geolocation.getCurrentPosition(function (position) {
              coordinates = `${position.coords.latitude} ${position.coords.longitude}`;
              sessionStorage.setItem("coords", `${position.coords.latitude} ${position.coords.longitude}`);
            });
            sessionStorage.setItem("loggedUser", JSON.stringify(data.customer));
            return checkStatus(clientId, validToken, myIp, data.customer.eMail, props.history, true);
          }
        } catch (e) {
          return props.history.push("/");
        }
      }
    };
    a();
  }, [token, clientId]);

  useEffect(() => {
    const t = setTimeout(() => {}, 2000);
    return () => clearTimeout(t);
  }, []);

  return <div style={{ minHeight: 700, display: "flex", justifyContent: "center", alignItems: "center" }}>Accediendo...</div>;
};

SSO.propTypes = {};
SSO.defaultValues = {};

export default SSO;
